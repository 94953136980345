/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Button } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Naše pečivo"}>
        <SiteHeader />

        <Column className="pb--80 pt--80" name={"info"}>
          
          <ColumnWrap className="column__flex --center el--1 mt--30 pb--40 pt--50" style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" style={{"maxWidth":""}} content={"Nasze pieczywo"}>
              </Title>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach. Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1ymcwpx --border2 js-anim  --anim6 --anim-s4 bg--center --full --parallax pb--60 pt--60" anim={"6"} name={"fotka"} animS={"4"} border={"2"} parallax={true} fullscreen={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/11212/bd398b2ab0c641659b86e2fb66a54aac_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/bd398b2ab0c641659b86e2fb66a54aac_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/bd398b2ab0c641659b86e2fb66a54aac_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/bd398b2ab0c641659b86e2fb66a54aac_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/bd398b2ab0c641659b86e2fb66a54aac_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/bd398b2ab0c641659b86e2fb66a54aac_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/bd398b2ab0c641659b86e2fb66a54aac_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/bd398b2ab0c641659b86e2fb66a54aac_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"info"}>
          
          <ColumnWrap className="column__flex --center el--1 mt--0 pb--30 pt--30" style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach. Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"fotogalerie"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --right el--1 pb--0 pl--06 pr--06 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":1110}} border={null}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/96/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/96/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/96/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/96/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/96/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/96/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box pl--0 pr--0" content={"Chleb wieloziarnisty, 500g  —  6,70zł"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --right el--2 mt--80 pb--0 pl--06 pr--06 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}} border={null}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/96/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/96/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/96/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/96/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/96/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/96/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box pl--0 pr--0" content={"Chleb wieloziarnisty, 500g  —  6,70zł"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left mb--0 mt--0 pb--0 pt--0" style={{"maxWidth":380}} border={null}>
              
              <Text className="text-box pl--0 pr--0" style={{"maxWidth":""}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--80" name={"vyzva"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--80 pt--80" style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Title>

              <Button className="btn-box" href={"/pl/"} content={"Odwiedź nas"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pl--40 pr--40 pt--40" name={"stopka"}>
          
          <ColumnWrap className="column__flex el--3 --full pl--20 pr--20 flex--bottom" style={{"maxWidth":""}} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Piekarnia Pańska 18<br>Pańska 18, Warszawa<br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":254}} content={"+48 797 811 2X0<br>info@twoje-stronyy.com<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":254}} content={"<a href=\"https://saywebpage.com\">Tworzenie stron</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Pon—Pt<br>7:00—15:00<br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":254}} content={"Sob—Nd<br>7:00—15:00<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}